<template>
  <div
    ref="inputTarget"
    class="c-func-select"
    :class="{
      'c-func-select-gray': isGray,
      'c-func-select-min': min,
      'c-func-select-disable': isDisabled,
      'c-func-select-required': isRequired
    }"
  >
    <icon
      class="c-func-select-icon"
      iconName="toggle"
      :class="{ active: isActive }"
      isButton
      :size="min ? 'small' : 'default'"
      @icon-click="toggleSelectBox(), $emit('select-box-open')"
    />
    <!-- select-box-open をemitして、viewsで表示する要素を取得する -->
    <template v-if="placeholder && selectItem === ''">
      <!-- placeholderがあり、選択していないとき -->
      <button
        class="c-func-select-button c-func-select-button-placeholder"
        @click="toggleSelectBox(), $emit('select-box-open')"
      >
        <texts
          class="c-func-select-button-text"
          :text="placeholder"
          size="small"
          color="gray"
        />
      </button>
    </template>
    <template v-else-if="firstSelectItem && selectItem === ''">
      <!-- firstSelectItem(最初に選択済みにしている項目)があり、変更していないとき -->
      <button
        class="c-func-select-button"
        @click="toggleSelectBox(), $emit('select-box-open')"
      >
        <texts
          class="c-func-select-button-text"
          :text="recipeFallback(firstSelectItem)"
          size="small"
        />
      </button>
    </template>
    <template v-else-if="selectItem !== ''">
      <!-- 選択済みの時 -->
      <button
        class="c-func-select-button"
        @click="toggleSelectBox(), $emit('select-box-open')"
      >
        <texts
          class="c-func-select-button-text"
          :text="recipeFallback(items[this.selectItem])"
          size="small"
        />
      </button>
    </template>
    <template v-else>
      <!-- placeholderがなく、選択していないとき -->
      <button
        class="c-func-select-button"
        @click="toggleSelectBox(), $emit('select-box-open')"
      >
        <texts
          class="c-func-select-button-text"
          :text="recipeFallback(items[0])"
          size="small"
        />
      </button>
    </template>
    <div class="c-func-underline" />
    <div
      ref="selectBoxInner"
      class="c-func-select-inner"
      :class="{
        active: isActive,
        scroll: scrollBar,
        'show-top': showTop,
        'show-separate': isSeparateBox
      }"
      :style="{
        '--offsetTop': offsetTop + 'px',
        '--offsetLeft': offsetLeft + 'px',
        '--width': inputWidth + 'px',
        '--scrollSize': scrollSizeCalc + 'vw'
      }"
      v-on:close="showMenu = false"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        v-tooltip="disabledTips(item)"
      >
        <button
          class="c-func-select-item"
          :value="item[valueKey]"
          :disabled="checkDisabled(item)"
          @click="!checkDisabled(item) ? toggleSelectBox(item, index) : null"
        >
          <icon
            v-if="showIcon"
            class="c-func-select-item-icon"
            :class="`c-func-select-item-icon-${item.iconColor}`"
            :iconName="item.iconName"
            :color="item.iconColor"
            size="small"
          />
          {{ recipeFallback(item) }}
          <icon
            v-if="showInfo"
            v-tooltip="item.info"
            class="c-func-select-item-info"
            iconName="info"
            size="small"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import icon from '@/components/atoms/icon.vue'
import texts from '@/components/atoms/text.vue'
import { checkErrori18n } from '@/lib/misc.js'

export default {
  components: {
    icon,
    texts
  },
  data() {
    return {
      isActive: false, // 選択項目が開いているかどうか
      selectItem: '', // 選択項目のID
      offsetTop: 0,
      offsetLeft: 0,
      inputWidth: 0,
      inputHeight: 0,
      showTopInList: false
    }
  },
  created() {
    if (this.value != null) {
      this.setValue(this.value)
    }
  },
  mounted() {
    this.setEvent(
      window,
      'click',
      function (e) {
        if (!this.$el.contains(e.target)) {
          this.isActive = false
        }
      }.bind(this)
    )
    if (this.isSeparateBox) {
      const target = this.$refs.inputTarget.getBoundingClientRect()
      this.inputWidth = target.right - target.left
      this.inputHeight = target.bottom - target.top
      const margin = this.margin
        ? this.margin
        : ((16 / 1920) * 100 * window.innerWidth) / 100 // adjustVW(16)を画面の大きさで再計算
      if (!this.showTop) {
        this.offsetTop = target.top + this.inputHeight / 2 + margin
        this.offsetLeft = target.left - this.marginLeft
        this.showTopInList = false
      } else {
        const sub = this.$refs.selectBoxInner.getBoundingClientRect()
        this.offsetTop = target.top - sub.height - margin
        this.offsetLeft = target.left - this.marginLeft
      }
    }
  },
  destroyed() {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function (eventRemover) {
        eventRemover.remove()
      })
    }
  },
  props: {
    /** v-modelで渡す値 */
    value: {},
    /** 選択項目として表示する要素 */
    items: Array,
    /** スクロールバーを表示 */
    scrollBar: Boolean,
    /** 背景が白いときに使用する */
    isGray: Boolean,
    /** 選択項目を取得するときに、初期状態で表示するテキスト */
    placeholder: String,
    /** 最初から選択状態にする要素があるときの要素自体 */
    firstSelectItem: Object,
    /** 最小化表示 */
    min: Boolean,
    /** 上方向にセレクトのポップアップを表示 */
    showTop: Boolean,
    /** emitする対象がvalueではなく他のkeyが必要であれば渡す */
    valueKey: {
      type: String,
      default: () => 'value'
    },
    /** iconを表示 itemsにiconNameを渡す必要がある */
    showIcon: Boolean,
    /** infoを表示 itemsにv-tooltipの中身を渡す必要がある */
    showInfo: Boolean,
    /** スクロール領域でセレクトボックスを切られたくないときにtrue */
    isSeparateBox: Boolean,
    /** セレクトボックスを切られたくないときに、どれだけ余白をあけて表示するかの数値 */
    margin: Number,
    /** セレクトボックスを切られたくないときに、どれだけ左の余白をあけて表示するかの数値 */
    marginLeft: {
      type: Number,
      default: 0
    },
    /** セレクトボックスにスクロール領域をつける場合に、セレクトボックスの大きさを指定 */
    scrollSize: {
      type: Number,
      default: 296
    },
    /** セレクトボックス自体をdisabled */
    isDisabled: {
      type: Boolean,
      default: false
    },
    /** 必須項目を強調したい場合 */
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    scrollSizeCalc() {
      return (this.scrollSize / window.innerWidth) * 100
    }
  },
  methods: {
    toggleSelectBox(e, index) {
      if (this.isSeparateBox) {
        this.checkPos()
        this.$nextTick(() => {
          this.isActive = !this.isActive
        })
      } else {
        this.isActive = !this.isActive
      }
      if (e !== undefined) {
        this.selectItem = index
        this.$emit('select-item', { selectItem: index, id: e.id })
        this.$emit('input', e[this.valueKey])
      }
    },
    checkPos() {
      // スクロールバーでセレクトボックスが切り取られないように、fixedで固定する位置の調整
      // このタイミングでは左方向のマージンは固定されているので再計算されない。何かしらの理由で再計算が必要になるなら、岡田に相談して追加して下さい
      const target = this.$refs.inputTarget.getBoundingClientRect()
      const margin = this.margin
        ? this.margin
        : ((16 / 1920) * 100 * window.innerWidth) / 100 // adjustVW(16)を画面の大きさで再計算
      if (!this.showTop) {
        this.offsetTop = target.top + this.inputHeight / 2 + margin
        this.offsetLeft = target.left - this.marginLeft
        this.showTopInList = false
      } else {
        const sub = this.$refs.selectBoxInner.getBoundingClientRect()
        this.offsetTop = target.top - sub.height - margin
        this.offsetLeft = target.left - this.marginLeft
      }
    },
    setValue(nv) {
      const i = this.items.findIndex((x) => x[this.valueKey] === nv)
      if (i >= 0) {
        this.selectItem = i
      }
    },
    // レシピブロックの中身がtextでvalueを渡してくることがあるので、念のためフォールバック
    recipeFallback(item) {
      if (item?.name || typeof item?.name === 'number') {
        return item.name
      } else if (item?.text || typeof item?.text === 'number') {
        return item.text
      } else {
        return ''
      }
    },
    setEvent(target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = []
      }
      target.addEventListener(eventType, callback)
      this._eventRemovers.push({
        remove() {
          target.removeEventListener(eventType, callback)
        }
      })
    },
    checkDisabled(item) {
      if (!item?.disabled?.reasons) return false
      return item.disabled.reasons.length > 0
    },
    disabledTips(item) {
      const reasons = item?.disabled?.reasons
      if (!reasons) return
      let content = ''
      reasons.forEach((item) => {
        const reason = checkErrori18n.bind(this)(item)
        if (content.length > 0) {
          content = content + '\n' + reason
        } else {
          content = reason
        }
      })
      return {
        content: content,
        trigger: 'hover',
        delay: { show: 300, hide: 300 }
      }
    }
  },
  watch: {
    value(nv) {
      this.setValue(nv)
    },
    items() {
      this.setValue(this.value)
    },
    isActive(newVal) {
      if (!newVal) {
        this.$emit('select-box-close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-func-select {
  position: relative;
  width: 100%;
  height: 100%;
  &-button {
    display: block;
    width: 100%;
    height: 100%;
    padding: $space-sub 0;
    border-bottom: $border-title-gray;
    &-text {
      text-align: left;
    }
    &:hover {
      opacity: 1;
    }
  }
  &-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: $transition-base;
    &.active {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  &-inner {
    position: absolute;
    top: calc(100% + #{$space-small});
    left: 0;
    width: 100%;
    padding: $space-small 0;
    margin-top: -10px;
    background: $background;
    font-size: $text-base;
    border-radius: adjustVW(8);
    box-shadow: $box-shadow-hover;
    opacity: 0;
    z-index: 100;
    transform: scaleY(0);
    transform-origin: top;
    transition: $transition-base;
    @include text-crop;
    &.active {
      margin: 0 0 $space-medium;
      opacity: 1;
      transform: scaleY(1);
      animation: 0.2s ease-in-out openItem;
    }
    &.scroll {
      --scrollSize: adjustVW(296);
      overflow-x: hidden;
      overflow-y: auto;
      max-height: var(--scrollSize);
      padding-right: $space-base;
      @include scrollbar;
    }
    &.show-top {
      top: inherit;
      bottom: calc(100% - #{$space-base});
      transform-origin: bottom;
    }
    &.show-separate {
      --offsetTop: 0;
      --offsetLeft: 0;
      --width: 0;
      position: fixed;
      top: var(--offsetTop);
      left: var(--offsetLeft);
      width: var(--width);
      z-index: -1;
      &.show-top {
        --offsetTop: 0;
        top: var(--offsetTop);
        bottom: inherit;
        transform: scaleY(1);
      }
      &.active {
        z-index: 100;
      }
    }
  }
  &-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: $space-small;
    margin: 0 0 $space-sub;
    text-align: left;
    word-break: break-all;
    opacity: 0;
    transform: translateY(-100%);
    transition: $transition-base;
    &:last-child {
      margin: 0;
      border: none;
    }
    &:hover {
      background: $background-sub;
      opacity: 1;
    }
    .active & {
      opacity: 1;
      transform: translateY(0);
      animation: 0.2s ease-in-out openMenuList;
    }
    &-icon {
      padding: $space-base;
      margin-right: $space-sub;
      background-color: $background-sub;
      border-radius: adjustVW(4);
      &-green {
        background-color: $green-bg;
      }
    }
    &-info {
      margin-left: auto;
    }
  }
  &-gray {
    .c-func-select-button {
      overflow: hidden;
      padding: $space-small adjustVW(40) $space-small $space-small;
      border: none;
      background: $background-sub;
      border-radius: adjustVW(8);
      transition: $transition-base;
      &:focus {
        background: $background;
        box-shadow: $border-radius-emphasis inset, $box-shadow-hover;
      }
    }
    .c-func-select-icon {
      right: $space-sub;
    }
  }
  &-min {
    .c-func-select-button {
      padding: 0 adjustVW(40) 0 $space-small;
    }
  }
  &-disable {
    cursor: not-allowed;
    button {
      pointer-events: none;
      cursor: not-allowed;
    }
    .c-func-select-inner {
      pointer-events: none;
    }
    .c-func-select-button-text {
      opacity: 0.3;
    }
    .c-func-select-icon {
      pointer-events: none;
      opacity: 0.3;
    }
    &.c-func-select-gray {
      .c-func-select-button {
        background-color: $lite-gray;
        box-shadow: 0 0 0 adjustVW(1) $medium-gray inset;
      }
    }
  }
  &-required {
    &.c-func-select-gray {
      .c-func-select-button {
        background-color: #fff;
        box-shadow: 0 0 0 adjustVW(1) $red inset, $box-shadow-hover;
      }
    }
  }
}

@keyframes openItem {
  0% {
    margin-top: -10px;
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    margin-top: 0;
    opacity: 1;
    transform: scaleY(1);
  }
}
@keyframes openItemList {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
