<!--
  大きなボタン一個しかボタンがない場合や、他の要素を選択した後に押してもらうボタンに使用
-->
<template>
  <button
    class="c-button-large"
    :class="{
      'c-button-large-disabled': isDisabled,
      'c-button-large-sub': isSub
    }"
    :disabled="isDisabled"
    @click="$emit('click')"
  >
    <template v-if="text">
      {{ text }}
    </template>
    <template v-else>
      <slot />
    </template>
  </button>
</template>

<script>
export default {
  props: {
    /** ボタンに表示するテキスト */
    text: String,
    /** ボタンを押下不可にするかどうか */
    isDisabled: Boolean,
    /** ボタンをサブカラーにする めったにないが、2つ並べるときに使う */
    isSub: Boolean
  }
}
</script>

<style lang="scss" scoped>
.c-button-large {
  width: 100%;
  min-width: adjustVW(216);
  min-height: adjustVW(64);
  padding: $space-small;
  background: $key-color;
  color: $text-func;
  font-size: $text-big;
  border-radius: adjustVW(4);
  transition: $transition-base;
  @include text-crop;
  &:hover {
    opacity: 0.5;
  }
  &-sub {
    background-color: $background;
    color: $text-main;
    box-shadow: 0 0 0 adjustVW(1) $text-main inset;
  }
  &-disabled {
    background: $border-gray;
    cursor: unset;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
