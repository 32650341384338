var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"icon",class:{
      'icon-caution': _vm.caution,
      'icon-sub': _vm.sub,
      'icon-disabled': _vm.isDisabled,
      'icon-small': _vm.size === 'small'
    }}),_c('texts',{staticClass:"text",class:{
      'text-small': _vm.size === 'small'
    },attrs:{"text":_vm.text,"color":_vm.isDisabled ? 'disabled' : '',"noTips":_vm.noTips,"size":_vm.size}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }