<template>
  <div class="item">
    <div
      class="icon"
      :class="{
        'icon-caution': caution,
        'icon-sub': sub,
        'icon-disabled': isDisabled,
        'icon-small': size === 'small'
      }"
    />
    <texts
      :text="text"
      class="text"
      :color="isDisabled ? 'disabled' : ''"
      :noTips="noTips"
      :size="size"
      :class="{
        'text-small': size === 'small'
      }"
    />
  </div>
</template>
1
<script>
import texts from '@/components/atoms/text'

export default {
  components: {
    texts
  },
  props: {
    /** 表示するテキスト */
    text: String,
    /** 注意カラー */
    caution: {
      type: Boolean,
      default: false
    },
    /** 緑色 */
    sub: {
      type: Boolean,
      default: false
    },
    /** 使用不可状態 */
    isDisabled: {
      type: Boolean,
      default: false
    },
    /** ツールチップを非表示 */
    noTips: {
      type: Boolean,
      default: false
    },
    /** サイズ default or small */
    size: {
      type: String,
      validator(val) {
        return ['small', 'default'].includes(val)
      },
      default: 'default'
    }
  }
}
</script>

<style lang="scss" scoped>
$base: 12;
$small: $base - 4;

.item {
  display: flex;
  align-items: center;
}
.icon {
  flex-shrink: 1;
  width: adjustVW($base);
  height: adjustVW($base);
  margin: 0 $space-base 0 0;
  background: $text-decoration;
  border-radius: 9in;
  &-caution {
    background: $text-caution;
  }
  &-sub {
    background: $green;
  }
  &-disabled {
    background: $border-gray;
  }
  &-small {
    width: adjustVW($small);
    height: adjustVW($small);
  }
}
.text {
  width: calc(100% - #{adjustVW($base) + $space-base});
  &-small {
    width: calc(100% - #{adjustVW($small) + $space-base});
  }
}
</style>
