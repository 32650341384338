var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"title",class:{
      'title-small': _vm.isTitleSmall
    }},[(_vm.title)?_c('texts',{attrs:{"text":_vm.title,"size":_vm.isTitleSmall ? 'min' : 'small',"color":"gray"}}):_vm._e(),_vm._t("title")],2),_c('div',{staticClass:"input"},[(_vm.nullError)?_c('icons',{staticClass:"input-icon-error",attrs:{"iconName":"error","size":"min","color":"caution"}}):(_vm.accept)?_c('icons',{staticClass:"input-icon-accept",attrs:{"iconName":"check","size":"min","color":"emphasis"}}):_vm._e(),(_vm.isPass)?_c('icons',{staticClass:"input-icon-pass",attrs:{"iconName":_vm.showPass ? 'eyeClose' : 'eyeOpen',"size":"min","isButton":""},on:{"icon-click":function($event){_vm.showPass = !_vm.showPass}}}):_vm._e(),_c('input',{ref:"inputTarget",staticClass:"input-inner input-inner-pass",class:{
        'input-inner-error': _vm.nullError,
        'input-inner-multi': _vm.isPass && _vm.value.length !== 0
      },attrs:{"name":_vm.name,"placeholder":_vm.placeholder,"type":_vm.isPass && !_vm.showPass ? 'password' : 'text'},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"focusin":function($event){_vm.showPopup = true},"focusout":function($event){_vm.isNameValidation ? (_vm.showPopup = true) : (_vm.showPopup = false)}}}),(_vm.popupView && !_vm.isNameValidation)?_c('popup-balloon-input',{class:{ 'input-popup-none-text': !_vm.popupText },attrs:{"showPopup":_vm.showPopup,"error":_vm.nullError,"isBottom":_vm.isBottom}},[_c('texts',{attrs:{"text":_vm.popupText,"size":"min","color":_vm.error ? 'caution' : 'default',"isShowAll":""}})],1):_vm._e()],1),(_vm.popupView && _vm.isNameValidation && _vm.error)?_c('popup-balloon-name-suggest',{staticClass:"input-popup-name-validation",attrs:{"error":_vm.error,"showPopup":_vm.showPopup,"nameValidationSuggests":_vm.nameValidationSuggests},on:{"select-suggest":_vm.selectSuggest}}):_vm._e(),(
      _vm.popupView &&
        _vm.emailSuggest &&
        _vm.nameValidationSuggests &&
        _vm.nameValidationSuggests.length > 0
    )?_c('popup-balloon-email-suggest',{staticClass:"input-popup-name-validation",attrs:{"error":_vm.error,"showPopup":_vm.showPopup,"nameValidationSuggests":_vm.nameValidationSuggests},on:{"select-suggest":_vm.selectSuggest}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }