/**
 * @param { Array } list
 * @param { string } newTerm
 * @returns { boolean | undefined }
 **/
export function duplicateChecker(list, newTerm) {
  if (newTerm === '') return undefined
  if (list.length === 0) return true

  if (typeof list[0] !== typeof newTerm) {
    throw new TypeError()
  }

  return !new Set(list).has(newTerm)
}

export function duplicateSuggests(list, newTerm) {
  if (newTerm === '') return undefined
  if (list.length === 0) return true

  if (typeof list[0] !== typeof newTerm) {
    throw new TypeError()
  }

  const set = new Set(list)
  const suggest = []
  let x = 1
  if (!set.has(newTerm)) {
    suggest.push(newTerm)
    set.add(newTerm)
  }
  while (suggest.length < 3) {
    const newName = newTerm + ' - ' + x
    if (!set.has(newName)) {
      suggest.push(newName)
      set.add(newName)
    }
    x++
  }

  return suggest
}
