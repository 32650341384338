<!--
  画面上に大きく表示しないリスト
-->
<template>
  <div class="list-item-wrap">
    <div
      class="list-item-key-block"
      :class="{ 'list-item-key-block-non-icon': nonIcon }"
    >
      <div v-for="key in field" :key="key.index" class="list-item-key-inner">
        <texts :text="key.label" isBold size="small" />
      </div>
    </div>
    <div class="list-item-entry-list">
      <template v-if="items && Object.keys(items).length > 0">
        <div
          v-for="(entry, index) in items"
          :key="index"
          class="list-item-entry-item"
        >
          <!-- トグルして要素を表示する場合 -->
          <button
            v-if="isToggle"
            class="list-item-entry-block"
            @click="listToggle(index), $emit('list-click', { id: index })"
          >
            <div
              v-for="key in field"
              :key="key.index"
              class="list-item-entry-inner"
            >
              <texts
                :text="entry[key.key]"
                :color="key.isCaution ? 'caution' : ''"
                size="small"
              />
            </div>
            <div v-if="!nonIcon" class="list-item-entry-icon">
              <icons
                class="list-item-entry-icon-toggle"
                iconName="toggle"
                :class="{
                  'list-item-entry-icon-toggle-on': showItems.some(
                    (item) => item === index
                  )
                }"
              />
            </div>
          </button>
          <!-- ボタンとして使用する場合 -->
          <button
            v-else-if="isButton"
            class="list-item-entry-block"
            @click="$emit('list-click', { id: index })"
          >
            <div
              v-for="key in field"
              :key="key.index"
              class="list-item-entry-inner"
            >
              <texts
                :text="entry[key.key]"
                :color="key.isCaution ? 'caution' : ''"
                size="small"
              />
            </div>
            <div v-if="!nonIcon" class="list-item-entry-icon">
              <icons iconName="forward" />
            </div>
          </button>
          <!-- リンクとして使用する場合 -->
          <router-link
            v-else-if="isLink"
            class="list-item-entry-block"
            :to="entry.link"
          >
            <div
              v-for="key in field"
              :key="key.index"
              class="list-item-entry-inner"
            >
              <texts
                :text="entry[key.key]"
                :color="key.isCaution ? 'caution' : ''"
                size="small"
              />
            </div>
            <div v-if="!nonIcon" class="list-item-entry-icon">
              <icons iconName="forward" />
            </div>
          </router-link>
          <!-- 選択できる要素にする場合 -->
          <button
            v-else-if="isSelect"
            class="list-item-entry-block"
            :class="{ 'list-item-entry-block-select': index === selectId }"
            @click="$emit('list-click', { id: index }), (selectId = index)"
          >
            <div
              v-for="key in field"
              :key="key.index"
              class="list-item-entry-inner"
            >
              <div v-if="key.key === 'name'" class="list-item-entry-name">
                <texts
                  class="list-item-entry-name-text"
                  :text="entry.name"
                  isBold
                />
                <description :text="entry.description" :line="2" />
              </div>
              <texts
                v-else
                :text="entry[key.key]"
                :color="key.isCaution ? 'caution' : ''"
                size="small"
              />
            </div>
          </button>
          <!-- 特に指定なくリストを表示する場合 -->
          <div v-else class="list-item-entry-block">
            <div
              v-for="key in field"
              :key="key.index"
              class="list-item-entry-inner"
            >
              <texts
                :text="entry[key.key]"
                :color="key.isCaution ? 'caution' : ''"
                size="small"
              />
            </div>
            <div v-if="!nonIcon" class="list-item-entry-icon">
              <icons iconName="forward" />
            </div>
          </div>
          <!-- トグルして要素を表示した際に、表示される要素 -->
          <transition
            name="list-toggle"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
          >
            <div
              v-if="isToggle && showItems.some((item) => item === index)"
              class="list-item-entry-toggle"
            >
              <!-- 表示される要素は親コンポーネント側で自由に設定 -->
              <div class="list-item-entry-toggle-inner">
                <slot name="toggleContent" v-bind:entry="entry" />
              </div>
            </div>
          </transition>
        </div>
      </template>
      <div v-else class="list-no-item">
        <images class="list-no-item-image" imageName="noItem" />
        <texts
          class="list-no-item-text"
          :text="$t('common.listNoItemSub')"
          size="small"
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import images from '@/components/atoms/images'
import description from '@/components/atoms/description'
import icons from '@/components/atoms/icon'

export default {
  components: {
    texts,
    images,
    description,
    icons
  },
  data() {
    return {
      showItems: [],
      selectId: ''
    }
  },
  props: {
    /** リストのラベル */
    field: Array,
    /** リスト本体 */
    items: [Object, Array],
    /** iconを非表示にする */
    nonIcon: {
      type: Boolean,
      default: false,
      requiredf: false
    },
    /** トグルできるリストかどうか(親コンポーネントで指定) */
    isToggle: {
      type: Boolean,
      default: false,
      required: false
    },
    /** ボタンとして使用するリストかどうか(親コンポーネントで指定) */
    isButton: {
      type: Boolean,
      default: false,
      required: false
    },
    /** リンクとして使用するリストかどうか(親コンポーネントで指定) */
    isLink: {
      type: Boolean,
      default: false,
      required: false
    },
    /** 選択できるリストかどうか(親コンポーネントで指定) */
    isSelect: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    listToggle(e) {
      // リストの開閉
      const targetIndex = this.showItems.findIndex((i) => i === e)
      if (targetIndex !== -1) {
        this.showItems.splice(targetIndex, 1)
      } else {
        this.$emit('open-toggle', e)
        this.showItems.push(e)
      }
    },
    beforeEnter(el) {
      el.style.maxHeight = 0 + 'vh'
    },
    enter(el) {
      el.style.maxHeight = 300 + 'vh'
    },
    beforeLeave(el) {
      el.style.maxHeight = (el.scrollHeight / window.innerHeight) * 100 + 'vh'
    },
    leave(el) {
      el.style.maxHeight = 0 + 'vh'
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  &-key {
    &-block {
      display: flex;
      padding: 0 adjustVW(40) $space-sub $space-sub;
      border-bottom: $border-title-gray;
      &-non-icon {
        padding: 0 $space-sub $space-sub;
      }
    }
    &-inner {
      overflow: hidden;
      display: flex;
      width: 100%;
      text-align: left;
    }
  }
  &-entry {
    &-list {
      display: flex;
      flex-direction: column;
    }
    &-item {
      width: 100%;
      border-bottom: $border-sub;
      &:last-child {
        border: none;
      }
    }
    &-block {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 $space-sub;
      transition: background-color $transition-base;
      &:hover {
        background: $background-sub;
        opacity: 1;
      }
      &-select {
        background: $background-decoration;
        &:hover {
          background: $background-decoration;
          opacity: 1;
        }
      }
    }
    &-inner {
      overflow: hidden;
      display: flex;
      width: 100%;
      padding: $space-small 0;
      text-align: left;
    }
    &-toggle {
      height: auto;
      padding: 0 $space-sub;
      transition: all $transition-base;
      &-inner {
        padding: $space-small 0;
      }
    }
    &-icon {
      margin: 0 0 0 $space-small;
      &-toggle {
        transition: transform $transition-base;
        &-on {
          transform: rotate(-180deg);
        }
      }
    }
    &-name {
      height: adjustVW(116);
      &-text {
        margin: 0 0 $space-base;
      }
    }
  }
}

.list {
  &-no-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: $space-medium 0 $space-base;
    &-image {
      width: adjustVW(320);
      height: auto;
      margin-bottom: $space-medium;
    }
    &-text {
      width: 100%;
      text-align: center;
    }
  }
}
.list-toggle-enter-active,
.list-toggle-leave-active {
  overflow: hidden;
}
.list-toggle-enter,
.list-toggle-leave-to {
  opacity: 0;
}
</style>
